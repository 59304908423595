import { useRef, useState, useEffect } from "react";
import { useLocation, useFetcher } from "@remix-run/react";

import { useToast } from "~/components/ui/use-toast";
import { useTranslate } from "./useTranslate";

import { FormSubmitResponse, ContactData } from "~/lib/types";

export const useContactForm = ({ form }: { form: HTMLFormElement | null }) => {
  const location = useLocation();
  const { toast } = useToast();
  const { translate } = useTranslate();

  const key = location.pathname + "/contact-form";

  const fetcher = useFetcher({ key });
  const isSubmitting = fetcher.state === "submitting";

  const [submitted, setSubmitted] = useState(false);
  const [validationErrors, setErrors] = useState<any>({});

  useEffect(() => {
    setSubmitted(false);
    setErrors({});
  }, []);

  useEffect(() => {
    if (submitted) {
      const formData = fetcher.data as FormSubmitResponse<ContactData>;
      if (formData) {
        const { success, message, errors } = formData;

        if (!success && errors) {
          setErrors(errors);
        }

        if (success && !errors) {
          setErrors({});
          form?.reset();
        }

        toast({
          title: success
            ? translate("contact.form.success.toast.title").toString()
            : translate("contact.form.error.toast.title").toString(),
          description: translate(message),
          variant: success ? "default" : "destructive",
        });
      }
    }

    return () => {
      setErrors({});
      setSubmitted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetcher.data]);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetcher.submit(e.currentTarget, {
      method: "post",
      action: "/action/contact-us",
      navigate: false,
      fetcherKey: key,
      preventScrollReset: true,
      unstable_flushSync: true,
    });
    setSubmitted(true);
  };

  return { submit, isSubmitting, validationErrors };
};
